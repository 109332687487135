export const FORM_MODE = {
  CREATE: 1,
  UPDATE: 2,
  VIEW: 3,
};

export const GENDERS = [
  {
    value: 0,
    label: 'Male',
  },
  {
    value: 1,
    label: 'Female',
  },
];
export const IS_PUBLIC_STATUS = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Không hiển thị',
  },
];

export const OBJECT_QA = [
  {
    value: 1,
    label: 'Cá nhân',
  },
  {
    value: 2,
    label: 'Tổ chức',
  },
  {
    value: 3,
    label: 'Tổng hợp',
  },
];
export const STATUS_APPROVAL = [
  {
    value: 1,
    label: 'Đã duyệt',
  },
  {
    value: 0,
    label: 'Chưa duyệt',
  },
  {
    value: -1,
    label: 'Từ chối',
  },
];

export const ARTICLE_STATUS_BAN_NHAP = 1;
export const ARTICLE_STATUS_GUI_DUYET = 2;
export const ARTICLE_STATUS_BIEN_TAP_LAI = 3;
export const ARTICLE_STATUS_CHO_XUAT_BAN = 4;
export const ARTICLE_STATUS_DUYET_LAI = 5;
export const ARTICLE_STATUS_XUAT_BAN = 6;
export const ARTICLE_STATUS_GO_BAI = 7;
export const STATUS_ARTICLE = [
  {
    value: ARTICLE_STATUS_BAN_NHAP,
    label: 'Bản nháp',
  },
  {
    value: ARTICLE_STATUS_GUI_DUYET,
    label: 'Gửi duyệt',
  },
  {
    value: ARTICLE_STATUS_BIEN_TAP_LAI,
    label: 'Biên tập lại ',
  },
  {
    value: ARTICLE_STATUS_CHO_XUAT_BAN,
    label: 'Chờ xuất bản',
  },
  {
    value: ARTICLE_STATUS_DUYET_LAI,
    label: 'Duyệt lại',
  },
  {
    value: ARTICLE_STATUS_XUAT_BAN,
    label: 'Xuất bản',
  },
  {
    value: ARTICLE_STATUS_GO_BAI,
    label: 'Gỡ bài',
  },
];
export const STATUS_ARTICLE_COLOR = [
  {
    value: ARTICLE_STATUS_BAN_NHAP,
    label: '#9196a4',
  },
  {
    value: ARTICLE_STATUS_GUI_DUYET,
    label: '#dcd724',
  },
  {
    value: ARTICLE_STATUS_BIEN_TAP_LAI,
    label: '#d966a0',
  },
  {
    value: ARTICLE_STATUS_CHO_XUAT_BAN,
    label: '#6941a4',
  },
  {
    value: ARTICLE_STATUS_DUYET_LAI,
    label: '#d0095a',
  },
  {
    value: ARTICLE_STATUS_XUAT_BAN,
    label: '#269322',
  },
  {
    value: ARTICLE_STATUS_GO_BAI,
    label: '#ccbfb1',
  },
];

export const AGENCY_TYPE = [
  {
    value: 1,
    label: 'Cơ quan',
  },
  {
    value: 2,
    label: 'Nhà mạng',
  },
  {
    value: 3,
    label: 'Bank',
  },
];

export const AGENCY_PROCESS = [
  {
    value: 0,
    label: 'Chưa có thông tin',
  },
  {
    value: 1,
    label: 'Chưa thực hiện',
  },
  {
    value: 2,
    label: 'Đang thực hiện',
  },
  {
    value: 3,
    label: 'Đã thực hiện',
  },
];

export const defaultButton = (menu) => {
  const buttonsForm = [
    { type: 'RESET' },
    // { type: 'SEARCH' },
    { type: 'CREATE', permission: `${menu}:create` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  const buttonActions = [
    { type: 'EDIT', permission: `${menu}:edit` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  return { buttonsForm, buttonActions };
};

export const defaultButtonApproval = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'APPROVAL' }, { type: 'CANCEL_APPROVAL' }];
  return { buttonsForm, buttonActions };
};
export const defaultButtonConfigAgency = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'SHOW' }, { type: 'HIDDEN' }];
  return { buttonsForm, buttonActions };
};
export const HIDDEN_MENUS = ['/system/agency/detail'];
export const STATUS_AGENCY = {
  show: 1,
  hidden: 0,
};
export const STATUS_AGENCY_TEXT = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Ẩn',
  },
];

export const LANGUAGE_TYPE = [
  {
    value: 'vi',
    label: 'Tiếng Việt',
  },
  {
    value: 'en',
    label: 'English',
  },
];

export const MEDIA_TYPE = [
  {
    value: 2,
    label: 'Ảnh',
  },
  {
    value: 4,
    label: 'Video',
  },
];
export const TYPE_BANNER = [
  {
    value: 1,
    label: 'Đầu trang',
  },
  {
    value: 2,
    label: 'Thân trang',
  },
];

export const TYPE_AGENCY = [
  {
    value: 1,
    label: 'Cơ quan ban hành',
  },
  {
    value: 2,
    label: 'Cơ quan tiếp nhận',
  },
];

export const APP_PARAM_TYPE = {
  TYPE_OF_WORK: 'type_of_work',
  EXPERIENCE: 'experience',
  EMPLOYEE_LEVEL: 'employee_level',
  WORKPLACE: 'workplace',
};

export const LANGUAGE = {
  VIETNAMESE: 'vi',
  ENGLISH: 'en',
};

export const CONTACT_READ = 1;
export const CONTACT_UNREAD = 0;
export const STATUS_CONTACT = [
  {
    value: CONTACT_READ,
    label: 'Đã xem',
  },
  {
    value: CONTACT_UNREAD,
    label: 'Mới',
  },
];
export const UNIT_TYPE = {
  DON_VI: 1,
  PHONG_BAN: 2,
};
export const NAVIGATION_TYPE = {
  PREV: -1,
  NEXT: 1,
};

export const TAB_KEY = {
  INFO: 'info',
  CAMERA: 'camera',
  HISTORY: 'history',
};

export const TAB_KEY_DASHBOARD = {
  DASHBOARD: 'dashboard',
  CUSTOMER: 'customer',
  STRANGER: 'stranger',
};

export const PROFILE_TYPE = {
  EMPLOYEE: 'NV',
  CUSTOMER: 'KH',
  VIP: 'VIP',
  BLACKLIST: 'BL',
  STRANGER: 'LA',
};

export const PROFILE_TYPE_OPTIONS = [
  { value: 'NV', label: 'profile.form.employee' },
  { value: 'KH', label: 'profile.form.customer' },
  { value: 'VIP', label: 'profile.form.VIP' },
  { value: 'BL', label: 'profile.form.blacklist' },
];
export const PROFILE_TYPE_OPTIONS_1 = [
  { value: 'NV', label: 'profile.form.employee' },
  { value: 'KH', label: 'profile.form.customer' },
  { value: 'VIP', label: 'profile.form.VIP' },
  { value: 'BL', label: 'profile.form.blacklist' },
  { value: 'LA', label: 'profile.form.stranger' },
];

export const IDENTITY_CARD_TYPE = [
  { value: '1', label: 'profile.form.cmt' },
  { value: '2', label: 'profile.form.cccd' },
  { value: '3', label: 'profile.form.passport' },
];
export const PROFILE_TYPE_COLOR = {
  NV: {
    text: 'event.staff',
    type: 'primary',
    color: 'bg-hrm-main1 text-hrm-white',
  },
  LA: {
    text: 'event.guest',
    type: 'info',
    color: 'bg-hrm-hong text-hrm-white',
  },
  KH: {
    text: 'event.customer',
    type: 'warning',
    color: 'bg-hrm-main3 text-hrm-white',
  },
  VIP: {
    text: 'event.vip',
    type: 'warning',
    color: 'bg-hrm-main3 text-hrm-white',
  },
  BL: {
    text: 'profile.form.blacklist',
    type: 'dark',
    color: 'bg-hrm-gray800 text-hrm-white',
  },
};
export const EVENT_STATUS = {
  XUAT_HIEN: {
    text: 'event.appear',
    type: 'info',
    color: 'text-hrm-coBan border-hrm-coBan',
  },
  ZONE_ACCESS_CONTROL_OUT: {
    text: 'event.checkout',
    type: 'error',
    color: 'text-hrm-vang700 border-hrm-vang700',
  },
  ZONE_ACCESS_CONTROL_IN: {
    text: 'event.checkin',
    type: 'warning',
    color: 'text-hrm-main3 border-hrm-main3',
  },
  ZONE_INTRUSION: {
    text: 'event.intrusion',
    type: 'link',
    color: 'text-hrm-hong border-hrm-hong',
  },
};
export const EVENT_STATUS_OPTION = [
  { value: 'ZONE_ACCESS_CONTROL_OUT', label: 'event.checkout' },
  { value: 'ZONE_ACCESS_CONTROL_IN', label: 'event.checkin' },
];

export const EVENT_DOOR_STATUS_OPTION = [
  { value: 'ZONE_ACCESS_CONTROL_OUT', label: 'event.ZONE_ACCESS_CONTROL_OUT' },
  { value: 'ZONE_ACCESS_CONTROL_IN', label: 'event.ZONE_ACCESS_CONTROL_IN' },
];

export const USER_POSITION = {
  GPS: 1,
  DOI_TAC: 2,
  NHAN_VIEN: 4,
};
export const GENDER = {
  M: 'common.male',
  F: 'common.female',
};
export const REGISTER_INOUT_STATUS = [
  { label: 'register_in_out.new_register', value: 1 },
  { label: 'register_in_out.checked_in', value: 2 },
  { label: 'register_in_out.checked_out', value: 3 },
];

export const REGISTER_INOUT_STATUS_TEXT = {
  NEW_REGISTER: 1,
  CHECKIN: 2,
  CHECKOUT: 3,
};

export const ATTENDANCE_TYPE = {
  ATTENDANCE: 'StatusAttended',
  NOT_ATTENDANCE: 'StatusNotAttended',
};
export const ATTENDANCE_TYPE_OPTIONS = [
  {
    label: 'common.StatusAttended',
    value: 'StatusAttended',
  },
  {
    label: 'common.StatusNotAttended',
    value: 'StatusNotAttended',
  },
];

export const TIME_SELECT = [
  { label: 'access_control.year', value: 'Y' },
  { label: 'access_control.quarter', value: 'Q' },
  { label: 'access_control.month', value: 'M' },
  { label: 'access_control.week', value: 'W' },
  { label: 'access_control.day', value: 'D' },
];

export const STATISTICAL_OBJECT = {
  YEAR: 'Y',
  QUARTER: 'Q',
  MONTH: 'M',
  WEEK: 'W',
  DAY: 'D',
  HOUR: 'H',
};

export const EVENT_TYPE = {
  1: {
    value: 1,
    text: 'event.normal',
    color: 'text-hrm-main3',
  },
  2: {
    value: 2,
    text: 'event.warning',
    color: 'text-hrm-main2',
  },
};
export const EVENT_TYPE_OPTIONS = [
  { label: 'event.normal', value: 1 },
  { label: 'event.warning', value: 2 },
];

export const DATE_TYPE = {
  YEAR: 'Y',
  MONTH: 'M',
  WEEK: 'W',
  DAY: 'D',
  TIME: 'T',
};

export const DATE_TYPE_OPTIONS = [
  { label: 'common.year', value: 'Y' },
  { label: 'common.month', value: 'M' },
  { label: 'common.week', value: 'W' },
  { label: 'common.day', value: 'D' },
  { label: 'common.time', value: 'T' },
];

export const LIST_PURPOSE = [
  { value: '1', label: 'register_in_out.appointment' },
  { value: '2', label: 'register_in_out.recruitment' },
  { value: '3', label: 'register_in_out.contract' },
  { value: '4', label: 'register_in_out.visit' },
  { value: '5', label: 'register_in_out.training' },
  { value: '6', label: 'register_in_out.repair' },
  { value: '7', label: 'register_in_out.inspection' },
  { value: '8', label: 'register_in_out.health_care' },
  { value: '9', label: 'register_in_out.utility' },
  { value: '10', label: 'register_in_out.others' },
];

export const LANE_CONTROL_TYPE_OPTION = [
  { value: 'ZONE_ACCESS_CONTROL_OUT', label: 'event.ZONE_ACCESS_CONTROL_OUT' },
  { value: 'ZONE_ACCESS_CONTROL_IN', label: 'event.ZONE_ACCESS_CONTROL_IN' },
];
export const LANE_CONTROL_TYPE = {
  ZONE_ACCESS_CONTROL_OUT: {
    text: 'event.ZONE_ACCESS_CONTROL_OUT',
    type: 'link',
    color: 'text-hrm-coBan',
  },
  ZONE_ACCESS_CONTROL_IN: {
    text: 'event.ZONE_ACCESS_CONTROL_IN',
    type: 'warning',
    color: 'text-hrm-main3',
  },
};
export const LANE_CONTROL_MOVE_OPTION = [
  { value: 0, label: 'event.is-move' },
  { value: 1, label: 'event.not-move' },
];

export const RESPONSE_TYPE = {
  IN_OUT: 1,
  DOOR: 2,
  INTRUSION: 3,
};

export const RESPONSE_TYPE_OPTIONS = [
  { value: '1', label: 'event.in-out' },
  { value: '2', label: 'event.open-door' },
  { value: '3', label: 'event.intrusion-event' },
];

export const RACE = {
  white: 'human.white',
  black: 'human.black',
  latino_hispanic: 'human.latino_hispanic',
  east_asian: 'human.east_asian',
  southeast_asian: 'human.southeast_asian',
  indian: 'human.indian',
  middle_eastern: 'human.middle_eastern',
};
export const GENDER_EVENT = {
  male: 'common.male',
  female: 'common.female',
};
export const TYPESTAFF = {
  Y: 1,
  N: 0,
};
export const BASE_WORKING_TIME = {
  BY_HOUR: 'byHour',
  BY_TIME: 'byDate',
};
export const ALLOW_OR_NOT = {
  ALLOW: 'allow',
  NOT_ALLOW: 'notAllow',
};
export const TIMEKEEPING_TYPE = {
  BY_TIME: 'byTime',
  BEFORE_TIME: 'beforeTime',
};
export const YES_OR_NO = {
  YES: 'yes',
  NO: 'no',
};

export const STATUS_OPTIONS = [
  { label: 'common.active', value: 1 },
  { label: 'common.inactive', value: 0 },
];

export const PERMIT_STATUS_OPTIONS = [
  { label: 'permission_application.pending_approval', value: 0 },
  { label: 'common.approval', value: 1 },
  { label: 'common.disapprove', value: 2 },
];

export const PERMIT_TYPE_OPTIONS = [
  { label: 'permission_application.leave', value: 1 },
  { label: 'permission_application.be_late', value: 2 },
  { label: 'permission_application.come_back_early', value: 3 },
];

export const DATE_TYPE_STATIC = [
  { label: 'common.year', value: 'Y' },
  { label: 'common.quarter', value: 'Q' },
  { label: 'common.month', value: 'M' },
  { label: 'common.week', value: 'W' },
  { label: 'common.day', value: 'D' },
];
export const CAMERA_STATUS = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTED: 'CONNECTED',
};

export const EVENT_DOOR = {
  OUT: 'ZONE_ACCESS_CONTROL_OUT',
  IN: 'ZONE_ACCESS_CONTROL_IN',
};

export const OCCUPATION_OPTION = [
  { label: 'common.staff', value: 'Staff' },
  { label: 'common.director', value: 'Director' },
  { label: 'common.manager', value: 'Manager' },
];

export const OCCUPATIONS = {
  Staff: 'common.staff',
  Manager: 'common.manager',
  Director: 'common.director',
};

export const ALERT_STATUS_OPTIONS = [
  { label: 'common.active', value: true },
  { label: 'common.inactive', value: false },
];

export const ALERT_LOCATION_SHOW = [
  { label: 'common.active', value: 'bottomLeft' },
  { label: 'common.inactive', value: 'bottomRight' },
];

const STATUS_LIST = [
  {label: 'Bad', value: 1},
  {label: 'Not good', value: 2},
  {label: 'Good', value: 3},
];

const STATUS = {
  BAD: 1,
  NOT_GOOD: 2,
  GOOD: 3
}
