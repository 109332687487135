import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { FORM_MODE } from '@/util/common-constant';
import { i18n } from '@/main';

const state = () => ({
  list: [],
  loading: false,
  visibleMenu: false,
  visibleButton: false,
  visibleIcon: false,
  api: ConstantAPI.menu,
});

const actions = {
  async getAll({ commit, state }, onlyMenu = 0) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.SEARCH, null, {onlyMenu});
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
      commit('setLoading', false);
    }
  },
  async preView({ commit }, row) {
    commit('setFormValue', {
      menu: row,
      formMode: FORM_MODE.VIEW,
    });
  },
  async preUpdate({ commit }, row) {
    commit('setFormValue', {
      menu: row,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preEditMenu({ commit }) {
    commit('preEditMenu');
  },
  async preCreateMenu({ commit }) {
    commit('preCreateMenu');
  },
  async preCreateButton({ commit }) {
    commit('preCreateButton');
  },
  async preChooseIcon({ commit }) {
    commit('preChooseIcon');
  },
  async setVisibleMenu({ state }, visible) {
    state.visibleMenu = visible;
  },
  async setVisibleButton({ state }, visible) {
    state.visibleButton = visible;
  },
  async setVisibleIcon({ state }, visible) {
    state.visibleIcon = visible;
  },
  async createMenu({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      if (await showConfirm(t('menu.confirm-save'))) {
        const response = await DataService.callApi(
          state.api.CREATE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('menu.create-menu-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async updateMenu({ commit, state }, payload) {
    const { t } = i18n.global;
    try {
      commit('setLoading', true);
      if (await showConfirm(t('menu.confirm-save'))) {
        const response = await DataService.callApi(
          state.api.UPDATE,
          {
            ...payload,
            id: state.menu.id,
            parentId: payload.parentId ? payload.parentId : null,
          },
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('menu.update-menu-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async delete({ state }, rows) {
    const { t } = i18n.global;
    try {
      if (!rows.length) {
        notification.error({
          message: t('common.notification'),
          description: t('common.empty-selected-rows'),
          duration: 4,
        });
        return false;
      }
      if (await showConfirm(t('menu.confirm-delete'))) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e && e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('menu.delete-menu-success'),
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
