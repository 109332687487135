import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { FORM_MODE } from '@/util/common-constant';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';

const state = () => ({
  listFloor: [],
  loading: false,
  api: ConstantAPI.BUILDING,
  visible: false,
  formMode: FORM_MODE.CREATE,
  buildingDetail: {},
  listUnit: [],
  listUnitByFloor: [],
});

const actions = {
  async getFloorByBuildingId({ state }, id) {
    try {
      const response = await DataService.callApi(
        state.api.FLOOR_BY_CONDITION,
        null,
        {
          buildingId: id,
          size: 9999
        }
      );
      let success;
      checkResponse(response, (res) => {
        success = true;
        state.listFloor = res.content ? res.content : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async createBuilding({ state }, payload) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(state.api.CREATE, payload);
      let success;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('area.create_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async updateBuilding({ state }, payload) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(state.api.UPDATE, payload);
      let success;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('area.update_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async deleteBuilding({ commit }, rows) {
    const { t } = i18n.global;
    let isSuccess = false;
    try {
      const response = await DataService.callApi(ConstantAPI.BUILDING.DELETE, {
        buildingId: rows.map((item) => item.buildingId).join(','),
      });
      checkResponse(response, () => {
        notification.success({
          message: t('common.notification'),
          description: t('area.delete_success'),
          duration: 4,
        });
        isSuccess = true;
      });
      commit('setLoading', false);
      return isSuccess;
    } catch (err) {
      console.log(err);
    }
  },
  async deleteUnitInFloor({ state }, params) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(state.api.DELETE_UNIT_FLOOR, params);
      let success;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('area.delete_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getUnitByFloor({ state }, { buildingId, floorOrder }) {
    try {
      const response = await DataService.callApi(state.api.UNIT_BY_FLOOR,null,{
        buildingId,
        floorOrder,
      });
      let success;
      checkResponse(response, (res) => {
        success = true;
        state.listUnitByFloor = res ? res : []
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getAllUnit({ state }) {
    try {
      const response = await DataService.callApi(ConstantAPI.LIST_UNIT.GET_ALL,null,{
        size: 9999
      });
      let success;
      checkResponse(response, (res) => {
        success = true;
        state.listUnit = res.content ? res.content : []
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async addUnitFloor({ state }, payload) {
    const { t } = i18n.global;
    try {
      const response = await DataService.callApi(state.api.ADD_UNIT_FLOOR, {
        unitFloors: payload
      });
      let success;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: t('common.notification'),
          description: t('area.create_success'),
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  setFormMode({ state }, mode) {
    state.formMode = mode;
  },
  setBuildingDetail({ state }, detail) {
    state.buildingDetail = detail;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
