export const TABLE_LINE_CHART = {
  LAND: 'LAND',
  RECRUITMENT: 'RECRUITMENT',
  CV: 'CV',
  CLASSIFIEDS: 'CLASSIFIEDS',
};

export const LABEL = {
  YEAR: 'Tháng',
  MONTH: 'Ngày',
};

export const PRE_LABEL = {
  DAY: 'Hôm qua',
  WEEK: 'Tuần trước',
  MONTH: 'Tháng trước',
  YEAR: 'Năm trước',
};

export const DAY_OF_WEEK = [
  'Chủ nhật',
  'Thứ 2',
  'Thứ 3',
  'Thứ 4',
  'Thứ 5',
  'Thứ 6',
  'Thứ 7',
];

export const REQUEST_TYPE = {
  BANK_SEARCH_ACC: 4,
  BANK_ORTHER: 5,
  TELE_SEARCH_IP: 1,
  TELE_MANAGE_IP: 2,
  TELE_ORTHER: 3,
};

export const REQUEST_STATUS = {
  SENT: 0,
  CANCEL: 1,
  SEND_BACK: 2,
  RECEIVED: 3,
  PROCESSED: 4,
};

export const RECEIVER = {
  ORGANIZATION: 1,
  NETWORK: 2,
  BANK: 3,
};

export const STATUS_NOTIFY_REQUEST = {
  REQUEST_PROCESSING: 0,
};

export const RECEIVER_OPTIONS = [
  { label: 'Nhà mạng', value: 2 },
  { label: 'Ngân hàng', value: 3 },
];

export const STATUS_ACTION = {
  INACTIVE: 0,
  ACTIVE: 1,
};

export const ACTION = {
  VIEW: 0,
  EDIT: 1,
  CREATE: 2,
};

export const CONFIG = {
  FIELDS: 'fields',
  GROUPS: 'groups',
  MAJORS: 'majors',
  TYPES: 'types',
  AGENCY: 'agency',
};

export const STATUS_LIST = [
  {label: 'Bad', value: 1},
  {label: 'Not good', value: 2},
  {label: 'Good', value: 3},
]
export const STATUS = {
  BAD: 1,
  NOT_GOOD: 2,
  GOOD: 3
}

export const RESULT_LIST = [
  {label: 'Success', value: 1},
  {label: 'Failed', value: 2},
]
export const RESULT = {
  SUCCESS: 1,
  FAILED: 2,
}
