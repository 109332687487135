import {
  computed,
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import VueTypes from 'vue-types';
import { forEachNested } from '@/util/common-utils';
import { useI18n } from 'vue-i18n';
import { DataService } from '@/dataService/dataService';

export default defineComponent({
  name: 'Table',
  props: {
    title: VueTypes.string,
    columns: VueTypes.array.def([]),
    clientPagination: VueTypes.bool.def(false),
    showSorterTooltip: VueTypes.bool,
    tableLayout: VueTypes.string,
    sticky: VueTypes.bool,
    showSizeChanger: VueTypes.bool.def(true),
    pagination: VueTypes.bool.def(false),
    pageSizeOptions: VueTypes.array.def([10, 20, 30, 50, 100]),
    paginationSize: VueTypes.string.def('small'),
    pageSize: VueTypes.number.def(10),
    indexColumn: VueTypes.bool.def(false),
    apiFetch: VueTypes.string.required,
    paramFetch: VueTypes.object.def({}),
    header: VueTypes.object,
    keyField: VueTypes.string.def('id'),
    hasCheckbox: VueTypes.bool.def(false),
    isDisableSelectRow: VueTypes.bool.def(false),
    checkStrictly: VueTypes.bool.def(true),
    scroll: VueTypes.object.def({ x: 1500 }),
    fetchOnMount: VueTypes.bool.def(true),
    mapData: VueTypes.func.def((e) => e),
    dataSource: VueTypes.array.def([]),
    showTotal: VueTypes.bool.def(true),
    bordered: VueTypes.bool.def(false),
    fixedStt: VueTypes.bool.def(false),
    pageNumber: VueTypes.number.def(null),
    rowClassName: VueTypes.any,
    scrollHeightModal: VueTypes.number.def(null),
  },
  setup(props, context) {
    const data = ref([]);
    const total = ref(0);
    const page = ref(props.pageNumber ? props.pageNumber : 1);
    const size = ref(props.pageSize);
    const loading = ref(false);
    const scrollHeight = ref(50);
    const scrollHeightModal = ref(props.scrollHeightModal);
    const { t } = useI18n();
    let tableColumns = computed(() => {
      if (!props.columns.length) return null;
      if (props.indexColumn)
        return [
          {
            title: t('common.stt'),
            align: 'center',
            width: 80,
            customRender({ index }) {
              return index + 1 + (page.value - 1) * size.value;
            },
            fixed: props.fixedStt ? 'left' : '',
          },
          ...props.columns,
        ];
      return props.columns;
    });

    let selectedRows = ref([]);
    const selectedRowsArray = ref([]);
    const rowSelection = props.hasCheckbox
      ? ref({
          selectedRowKeys: selectedRowsArray,
          checkStrictly: props.checkStrictly,
          onSelect: (record, selected, rows) => {
            selectedRows.value = rows;
          },
          onSelectAll: (selected, rows) => {
            selectedRows.value = rows;
          },
          onChange: (selectedRowKeys) => {
            selectedRowsArray.value = selectedRowKeys;
          },
          getCheckboxProps: (record) => {
            if (props.isDisableSelectRow) {
              return {
                disabled: !!record.status,
              };
            }
          },
        })
      : null;
    const getScrollHeight = () => {
      const table = document.getElementsByClassName('scroll-table-to-top');
      if (table && table[0]) scrollHeight.value = table[0].clientHeight - 55;
    };
    const resetAndFetch = () => {
      page.value = 1;
      size.value = props.pageSize;
      selectedRows.value = [];
      fetchData().then();
    };
    const fetchData = async (resetPage) => {
      try {
        if (resetPage) {
          page.value = 1;
        }
        loading.value = true;
        const response = await DataService.callApi(
          props.apiFetch,
          null,
          {
            ...props.paramFetch,
            page: page.value - 1,
            size: size.value,
          },
          props.header
        );
        const res = response.data;
        if (props.pagination) {
          data.value = props.hasCheckbox
            ? (res.content || []).map((r) => ({
                ...r,
                key: r[props.keyField],
              }))
            : res.content || [];
          data.value = data.value.map(props.mapData);
          total.value = res.totalElements;
        } else {
          data.value = res;
          if (props.hasCheckbox) {
            data.value.forEach((p) =>
              forEachNested(p, (c) => (c.key = c[props.keyField]))
            );
          }
        }
        loading.value = false;
        getScrollHeight();
        const table = document.getElementsByClassName('ant-table-body');
        table[0].scrollTo({ top: 0, behavior: 'smooth' });
      } catch (e) {
        console.log(e);
        page.value = 1;
        size.value = props.pageSize;
        data.value = [];
        loading.value = false;
      } finally {
        selectedRowsArray.value = [];
      }
    };
    const sizeChange = () => {
      page.value = 1;
    };
    const customRow = (record) => {
      return {
        onClick: () => context.emit('onRowClicked', record),
      };
    };

    const onChangePage = () => {
      fetchData(false).then();
      selectedRows.value = [];
      context.emit('changePage', page.value);
    };

    onMounted(() => {
      if (!props.apiFetch) {
        data.value = props.dataSource;
      } else if (props.fetchOnMount) fetchData().then();
      getScrollHeight();
      window.addEventListener('resize', getScrollHeight);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', getScrollHeight);
    });

    watch(
      () => props.dataSource,
      () => {
        if (!props.apiFetch) {
          data.value = props.dataSource;
        }
      }
    );
    return {
      // table
      table: ref(null),
      customRow,
      sizeChange,
      fetchData,
      getScrollHeight,
      resetAndFetch,
      data,
      total,
      loading,
      tableColumns,
      handleResizeColumn: (col, w) => {
        col.width = w;
      },
      rowSelection,
      selectedRows,
      // pagination
      page,
      size,
      onChangePage,
      selectedRowsArray,
      scrollHeight,
      t,
      scrollHeightModal,
    };
  },
});
