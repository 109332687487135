import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';

const state = () => ({
  loading: false,
  api: ConstantAPI.ALERT_CONFIGURATION,
  visible: false,
  listAll: [],
  listCamera: [],
  listProfile: [],
});

const actions = {
  async getAll({ state }, params) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL, null, params);
      state.listAll = response.data.content || []
    } catch (err) {
      console.log(err);
    }
  },
  async getAllCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
          ConstantAPI.CAMERA.GET_CAMERA_IN_GROUP,
          null,
          params
      )
      state.listCamera = response || []
    } catch (err) {
      console.log(err);
    }
  },
  async getProfiles({ state }, params) {
    try {
      if (params) {
        const response = await DataService.callApi(
            ConstantAPI.profileManagement.GET_ALL,
            null,
            params
        )
        state.listProfile = response.data.content.map(e => ({...e, fullName: `${e.firstName} ${e.lastName}`})) || []
      } else {
        state.listProfile = []
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
